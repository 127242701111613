import { useEffect, useState } from "react";

import { breakpointObj } from "@eriksdigital/atomic-ui/styles";

export const BREAKPOINTS_LABELS = {
  phone: "phone",
  phoneLarge: "phoneLarge",
  tabletPortrait: "tabletPortrait",
  tabletIpadPortrait: "tabletIpadPortrait",
  tabletLandscape: "tabletLandscape",
  desktop: "desktop",
  giant: "giant",
};

export const getMediaQueryByLabel = (label: string) =>
  `min-width: ${breakpointObj[label] / 16}em`;

export const useMedia = (label: string) => {
  const query = "(" + getMediaQueryByLabel(label) + ")";
  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = () => setMatches(media.matches);
    //TORM when ie11 suport is dropped
    if (media.addEventListener) {
      media.addEventListener("change", listener);
    } else if (media.addListener) {
      media.addListener(listener);
    }
    return () => {
      if (media.removeEventListener) {
        media.removeEventListener("change", listener);
      } else if (media.removeListener) {
        media.removeListener(listener);
      }
    };
  }, [matches, query]);

  return matches;
};
