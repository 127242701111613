import styled from "styled-components";
import { P, Box, Price, Image } from "@eriksdigital/atomic-ui/components";
import { spacing, breakpoints } from "@eriksdigital/atomic-ui/styles";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.sp16};

  border-top: 1px solid ${({ theme }) => theme.colors.border.gray};

  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.gray};
  }

  ${breakpoints.tabletLandscape`
    flex-direction: row;
    padding: ${spacing.sp16} ${spacing.sp16};
  `}
`;

export const TextWrapper = styled.div`
  margin-left: ${({ theme }) => theme.spacing.sp16};
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft20};
  font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
`;
export const EriksNumber = styled(P)`
  font-size: ${({ theme }) => theme.fonts.fontSize.ft16};
  color: ${({ theme }) => theme.colors.text.complementary};
`;

export const TemperatureInfo = styled(P)`
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
`;

export const PriceBlock = styled(Box)`
  width: 100%;
  ${breakpoints.tabletLandscape`
    width: auto;  
  `}
`;

export const NoPriceMessage = styled(Box)`
  width: 100%;
  ${breakpoints.tabletLandscape`
    width: 60%;
  `}
`;

export const ItemDescription = styled(Box)`
  flex: 0 0 40%;
  display: flex;
  flex-wrap: nowrap;
`;

export const ItemPrice = styled(Box)`
  ${breakpoints.phone`
  flex: 1 1 100%;
`}
  ${breakpoints.tabletLandscape`
  flex: 0 0 60%;
  display: flex;
  justify-content: center;
  `}
`;

export const PriceWrapper = styled(Price)`
  ${breakpoints.phone`
    flex: 1 1 100%;
    margin-bottom: 1rem;
  `}
  ${breakpoints.tabletLandscape`
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`;

export const ItemStock = styled(Box)`
  display: flex;
  ${breakpoints.phone`
    flex: 1 1 100%;
    margin: 1rem 0;
  `}
  ${breakpoints.tabletLandscape`
    margin: 0;
    flex: 0 0 40%;
    justify-content: center;
    align-items: flex-start;
  `}
`;

export const ItemInventory = styled(Box)`
  flex: 0 0 60%;
  display: flex;
  // justify-content: center;
  ${breakpoints.phone`
    flex-direction: column;
  `}
  ${breakpoints.tabletLandscape`
    flex-direction: row;
    justify-content: flex-start;
  `}
`;

export const IconImage = styled.img`
  width: 40px;
  padding: 5px 5px 0 0;
`;

export const ImageWrapper = styled(Image)`
  height: auto;
`;
