import React, { useContext } from "react";
import {
  AddToCart as A2C,
  LanguageContext,
  ShoppingCartContext,
  NotificationContext,
} from "@eriksdigital/atomic-ui/components";
import { hooks } from "@eriksdigital/atomic-ui/utils";
import { FormattedMessage } from "react-intl";
import { GTMContext } from "@eriksdigital/gs-ga4";
import { languageToCode } from "../../../../utils/languageUtils";
import { AddToCartWrapper } from "./styles";
import { ItemData } from "../index";

export const AddToCart = ({
  data,
  position,
}: {
  data: ItemData;
  position: number;
}) => {
  const { erpSystem, language } = useContext(LanguageContext);
  const { createNotification } = useContext(NotificationContext);
  const { fetchShoppingCart } = useContext(ShoppingCartContext);
  const { sendAddToCartEvent } = useContext(GTMContext);

  const handleAddToCartSuccess = () => {
    createNotification &&
      createNotification({
        id: `${Date.now()}-${data.erpPartNumber}`,
        variant: "success",
        message: (
          <FormattedMessage
            id="add-to-cart.success"
            defaultMessage="The product was added to your shopping cart"
            description="Item is succesfully added to the shoping cart"
          />
        ),
      });
    fetchShoppingCart && fetchShoppingCart();
  };

  const handleAddToCartError = () => {
    createNotification &&
      createNotification({
        id: `${Date.now()}-${data.erpPartNumber}`,
        variant: "error",
        message: (
          <FormattedMessage
            id="add-to-cart.error"
            defaultMessage="Our apologies! Something went wrong when adding the product to your shopping cart. Please contact the ERIKS support team."
            description="Item was not added to the shoping cart"
          />
        ),
      });
    fetchShoppingCart && fetchShoppingCart();
  };

  const { addToCart } = hooks.useAddToCart({
    onCompleted: handleAddToCartSuccess,
    onError: handleAddToCartError,
  });

  const handleAddToCart = async (quantity: number) => {
    sendAddToCartEvent &&
      sendAddToCartEvent({
        itemName: data.description,
        itemId: data.partNumber,
        price: data.priceRaw,
        itemBrand: data.brand,
        itemStockStatus: data.stockStatus.available,
        index: position,
        materialNumber: data.erpPartNumber,
        quantity,
      });
    addToCart({
      variables: {
        locale: languageToCode(language),
        erpSystem: erpSystem.toUpperCase(),
        erpPartNo: data.erpPartNumber,
        quantity,
        targetSystem: "SEL_ORI",
      },
    });
  };

  return (
    <AddToCartWrapper>
      <A2C
        table
        id={data.erpPartNumber}
        data-testid={data.erpPartNumber}
        onAdd={handleAddToCart}
        quantityInitialValue={1}
        addToCartRound
        quantityRounded
      />
    </AddToCartWrapper>
  );
};
