import React, { FC, useState, useEffect, useContext } from "react";
import { Canvas } from "@react-three/fiber";
import { Vector3, DoubleSide } from "three";
import { FormattedMessage } from "react-intl";
import { LanguageContext } from "@eriksdigital/atomic-ui/components";
import { translatedColorsMap } from "../../../../components/ColorPalette/colorsMap";
import { Language } from "../../../../utils/languageUtils";
import { TextContainer } from "./styles";
import { Zoomer } from "../Zoomer";

export type PreviewProps = {
  colour?: string;
  internalDiameter?: number;
  crossSection?: number;
};

const INITIAL_ZOOM = 100;
const VECTOR_INNER_DIAMETER = 1;
const INITIAL_VECTOR_CROSS_SECTION = 0.1;

/* Renders 3d preview of the oring based on entered values
 *
 * The radius of the torurs (VECTOR_INNER_DIAMETER) is always fixed to 1, and tube radius (vectorCrossSection) is calculated
 * as a ratio of entered internalDiameter and crossSection. until all values needed are entered (color, internalDiameter,
 * crossSection) component renders grey transparent "ghost" oring. To keep the 3d render inside the bounding box, we are
 * dynamically zooming out the canvas based on the calculated size of the ring
 */
const Preview3d: FC<PreviewProps> = ({
  colour,
  internalDiameter,
  crossSection,
}: PreviewProps) => {
  const { language } = useContext(LanguageContext);
  const [vectorCrossSection, setVectorVectorCrossSection] = useState(
    INITIAL_VECTOR_CROSS_SECTION
  );
  const [hexColor, setHexColor] = useState("#ccc");
  const [shouldShowGhost, setShouldShowGhost] = useState(true);
  const [zoom, setZoom] = useState(INITIAL_ZOOM);

  useEffect(() => {
    if (!internalDiameter || !crossSection) {
      setShouldShowGhost(true);
      setVectorVectorCrossSection(INITIAL_VECTOR_CROSS_SECTION);
    }
    if (internalDiameter && crossSection) {
      setVectorVectorCrossSection(
        Number(crossSection) / (Number(internalDiameter) + Number(crossSection))
      );
    }
    if (colour) {
      setHexColor(translatedColorsMap[language as Language][colour]);
    } else {
      setHexColor("#ccc");
    }
    if (
      internalDiameter &&
      crossSection &&
      colour &&
      translatedColorsMap[language as Language][colour]
    ) {
      setShouldShowGhost(false);
    }
  }, [internalDiameter, crossSection, colour, language]);

  useEffect(() => {
    setZoom(INITIAL_ZOOM - (vectorCrossSection * INITIAL_ZOOM) / 2);
  }, [vectorCrossSection]);

  return (
    <>
      <Canvas
        orthographic
        camera={{ zoom: INITIAL_ZOOM, fov: INITIAL_ZOOM / 50 }}
      >
        <Zoomer zoom={zoom} />
        <directionalLight position={new Vector3(0, 3, 2)} />
        <directionalLight position={new Vector3(-4, 3, 2)} />
        <mesh position={[0, 0, 0]} rotation={[0, -0.3, 0]}>
          <torusGeometry
            args={[VECTOR_INNER_DIAMETER, vectorCrossSection, 100, 100]}
          />
          {shouldShowGhost ? (
            <meshPhongMaterial
              color={hexColor}
              opacity={0.2}
              transparent={true}
              side={DoubleSide}
            />
          ) : (
            <meshPhongMaterial
              color={hexColor}
              opacity={1}
              transparent={false}
            />
          )}
        </mesh>
      </Canvas>
      <TextContainer>
        <p>
          <FormattedMessage
            id="form.internalDiameter"
            defaultMessage="Internal diameter (mm)"
            description="Label for internal diameter"
          />
          : {internalDiameter}
        </p>
        <p>
          <FormattedMessage
            id="form.crossSection"
            defaultMessage="Cross section (mm)"
            description="Label for cross section"
          />
          : {crossSection}
        </p>
      </TextContainer>
    </>
  );
};

export { Preview3d };
