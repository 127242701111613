import React, { useState, useContext, useEffect } from "react";
import {
  H2,
  Box,
  LanguageContext,
  LoginContext,
} from "@eriksdigital/atomic-ui/components";
import { Helmet } from "react-helmet-async";
import { FormattedMessage, useIntl } from "react-intl";
import { GTMContext } from "@eriksdigital/gs-ga4";
import ORingSelectorForm, { IValues } from "./ORingSelectorForm/index";
import Results, { ItemData } from "./Results";
import { ContainerWrapper, Content } from "./styles";
import { useLazyQuery } from "@apollo/react-hooks";
import { GET_O_RINGS_ITEMS } from "./Results/queries";
import { ResultActions, ResultActionsPagination } from "./Results/styles";
import { GET_O_RING_ATTRIBUTES } from "./queries";
import ShowMore from "./Results/ShowMore";
import NoResults from "../../components/NoResult";
import { transformData } from "../../utils/json-helpers";
import { GET_STOCK_LEVEL } from "./Results/StockInformation/queries";

export interface Attribute {
  value: string;
  internalDiameter?: number;
  crossSection?: number;
}

export interface OptionsList {
  [key: string]: Attribute[] | number;
}

export interface ResultsState {
  results: ItemData[];
  shouldMakeStockCall: boolean;
  shouldSendViewEvent: boolean;
}

export type Result = { [key: string]: any };

const ORingSelector = () => {
  const intl = useIntl();
  const LIMIT_PER_REQUEST = 5;
  const { language, erpSystem } = useContext(LanguageContext);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [hasNoResults, setHasNoResults] = useState<boolean>(false);
  const [
    { results, shouldMakeStockCall, shouldSendViewEvent },
    setResultsState,
  ] = useState<ResultsState>({
    shouldMakeStockCall: false,
    results: [],
    shouldSendViewEvent: false,
  });
  const [attributes, setAttributes] = useState<OptionsList>({});
  const [formData, setFormData] = useState<Result>({});
  const [currentLimitResults, setCurrentLimitResults] = useState(
    LIMIT_PER_REQUEST
  );

  const {
    userType,
    userId,
    userSegment,
    loggedIn,
    loginLoading,
    customerNumber,
    organizationName,
    userSalesChannel,
  } = useContext(LoginContext);
  const {
    sendProcessStartEvent,
    isReadyToSendEvents,
    sendPageViewEvent,
    sendViewItemEvent,
  } = useContext(GTMContext);

  useEffect(() => {
    if (isReadyToSendEvents && sendPageViewEvent && loginLoading === false) {
      sendPageViewEvent({
        pageUrl: window.location.href,
        pagePath: window.location.pathname,
        pageHostname: window.location.hostname,
        organizationId: customerNumber || "",
        organizationName: organizationName || "",
        userSalesChannel,
        userType,
        userId,
        userSegment,
        loggedIn,
      });
    }
  }, [
    isReadyToSendEvents,
    sendPageViewEvent,
    erpSystem,
    loginLoading,
    userType,
    userId,
    userSegment,
    customerNumber,
    organizationName,
    userSalesChannel,
    loggedIn,
  ]);
  useEffect(() => {
    if (isReadyToSendEvents && sendProcessStartEvent) {
      sendProcessStartEvent({ processStepName: "1_chooseproductdetails" });
    }
  }, [sendProcessStartEvent, isReadyToSendEvents]);

  const filterQuery = {
    lang: language,
    erpSystem: erpSystem.toUpperCase(),
    limit: currentLimitResults,
  };

  const onSubmit = (form: IValues) => {
    const data = transformData(form);
    setFormData(data);
    setCurrentLimitResults(LIMIT_PER_REQUEST);
    getItems({
      variables: { ...filterQuery, limit: LIMIT_PER_REQUEST, ...data },
    });
  };

  const [getAttributes] = useLazyQuery(GET_O_RING_ATTRIBUTES, {
    onCompleted: ({ getORingAttributes: data }) => {
      if (data) {
        const result: Result = {};
        Object.keys(data).forEach((item) => {
          if (item !== "__typename") {
            result[item] = data[item];
          }
        });
        setAttributes(result);
      }
    },
    fetchPolicy: "network-only",
  });

  const [getItems, { loading }] = useLazyQuery(GET_O_RINGS_ITEMS, {
    onCompleted: ({ getORingItems: { items, hasMore } }) => {
      setResultsState({
        results: items,
        shouldMakeStockCall: true,
        shouldSendViewEvent: false,
      });
      setHasMore(hasMore);
    },
    fetchPolicy: "network-only",
  });
  const [getStockLevels, { loading: stockLoading }] = useLazyQuery(
    GET_STOCK_LEVEL,
    {
      onCompleted: (res) => {
        setResultsState((prevState) => {
          return {
            results: prevState.results.reduce(
              (acc: ItemData[], cur: ItemData) => {
                const curStockStatus = res.getStockLevels.find(
                  (stockStatus: { number: string | undefined }) =>
                    stockStatus.number === cur.erpPartNumber
                );
                return [
                  ...acc,
                  {
                    ...cur,
                    stockStatus: curStockStatus,
                  },
                ];
              },
              []
            ),
            shouldMakeStockCall: false,
            shouldSendViewEvent: true,
          };
        });
      },
    }
  );

  useEffect(() => {
    if (shouldMakeStockCall && results?.length > 0) {
      getStockLevels({
        variables: {
          erpSystem: erpSystem.toUpperCase(),
          itemsNumbers: results?.map((result) => result.erpPartNumber),
        },
      });
    }
  }, [results, erpSystem, shouldMakeStockCall, getStockLevels]);

  useEffect(() => {
    if (shouldSendViewEvent && sendViewItemEvent) {
      sendViewItemEvent(
        results.map((item, index) => {
          const { stockStatus } = item;
          return {
            itemName: item.description,
            price: loggedIn ? item.priceRaw : "",
            itemId: item.partNumber,
            itemBrand: item.brand,
            itemStockStatus: stockStatus.available,
            index: index + 1,
            materialNumber: item.erpPartNumber,
          };
        })
      );
      setResultsState((prev) => {
        return {
          ...prev,
          results: [...prev.results],
          shouldSendViewEvent: false,
          shouldMakeStockCall: false,
        };
      });
    }
  }, [shouldSendViewEvent, results, sendViewItemEvent, loggedIn]);

  const handleChangeResultsShowMore = (currentLimitResults: number) => {
    getItems({
      variables: { ...filterQuery, limit: currentLimitResults, ...formData },
    });
    setCurrentLimitResults(currentLimitResults);
  };

  const showNoResults = (submitFailed: boolean, formInputData: Result) => {
    setFormData(transformData(formInputData));
    setHasNoResults(submitFailed);
  };

  const resetFormhandler = () => {
    setHasNoResults(false);
    setResultsState({
      results: [],
      shouldMakeStockCall: false,
      shouldSendViewEvent: false,
    });
    window.Form.restart();
  };

  useEffect(() => {
    getAttributes({
      variables: {
        lang: language,
        erpSystem: erpSystem.toUpperCase(),
      },
    });
  }, [getAttributes, language, erpSystem]);

  return (
    <Box marginTop="sp24" marginBottom="sp24">
      <Helmet>
        <title>
          {intl.formatMessage({
            id: "title",
            description: "title of the application",
            defaultMessage: "O-ring Selector",
          })}
        </title>
      </Helmet>
      <H2>
        <FormattedMessage
          id="title"
          defaultMessage="O-ring Selector"
          description="title of the application"
        />
      </H2>
      <Box marginTop="sp16" marginBottom="sp16">
        <ContainerWrapper>
          <Content>
            <ORingSelectorForm
              onSubmit={onSubmit}
              attributes={attributes}
              getAttributes={getAttributes}
              showNoResults={showNoResults}
              resetForm={resetFormhandler}
            />
          </Content>
        </ContainerWrapper>
        {results?.length || loading ? (
          <ResultActions>
            <Results
              items={results}
              loading={loading}
              stockLoading={stockLoading}
            />
            {hasMore && (
              <ResultActionsPagination>
                <ShowMore
                  loadMore={handleChangeResultsShowMore}
                  limit={currentLimitResults + LIMIT_PER_REQUEST}
                />
              </ResultActionsPagination>
            )}
          </ResultActions>
        ) : null}
        {!results?.length && hasNoResults && (
          <NoResults data={formData} resetForm={resetFormhandler} />
        )}
      </Box>
    </Box>
  );
};

export default ORingSelector;
