import React, { useContext, useEffect } from "react";
import {
  Icon,
  ShoppingCartContext,
  Span,
  Link,
  LanguageContext,
} from "@eriksdigital/atomic-ui/components";
import {
  CartIcon,
  LoadingIcon,
} from "@eriksdigital/atomic-ui/components/Icons";

import { colors } from "@eriksdigital/atomic-ui/styles";
import { IconWrapper, ItemCountCircle } from "./style";
import { constructShoppingUrl } from "../../utils/shopUtils";
import { getSalesOrganizations } from "./schema/getSalesOrganizations";
import { useQuery } from "@apollo/react-hooks";
import { GET_SALES_ORGANIZATIONS } from "./queries";
import { AppContext } from "../../providers/AppProvider";

const ShoppingCart: React.FC = () => {
  const { erpSystem, language } = useContext(LanguageContext);
  const { shoppingCart, shoppingCartLoading, fetchShoppingCart } = useContext(
    ShoppingCartContext
  );

  const { data } = useQuery<getSalesOrganizations>(GET_SALES_ORGANIZATIONS);
  const x_totalOrderItemCount =
    shoppingCart?.getShoppingCart?.x_totalOrderItemCount ?? 0;
  const {
    updateState,
    state: { shoppingCartUrl },
  } = useContext(AppContext);

  useEffect(() => {
    fetchShoppingCart();
  }, [fetchShoppingCart]);

  useEffect(() => {
    if (data && language && erpSystem) {
      const url = constructShoppingUrl(data, language, erpSystem);
      updateState({
        shoppingCartUrl: url,
      });
    }
  }, [data, language, erpSystem, updateState]);
  return (
    <div id="navbar-cart-icon">
      <Link
        href={data && language && erpSystem && shoppingCartUrl}
        target="_blank"
        rel="noopener noreferrer"
        data-testid="navbar-go-to-cart"
      >
        <IconWrapper>
          <ItemCountCircle isLargeAmount={x_totalOrderItemCount > 99}>
            {shoppingCartLoading ? (
              <Icon as={LoadingIcon} size="sz8" color={colors.default.blueD} />
            ) : (
              x_totalOrderItemCount
            )}
          </ItemCountCircle>
          <Span id="cart-itemcount" style={{ display: "none" }}>
            {x_totalOrderItemCount}
          </Span>
          <Icon
            as={CartIcon}
            fill={colors.heading.primary}
            height={30}
            width={30}
            viewBox="0 0 35 35"
          />
        </IconWrapper>
      </Link>
    </div>
  );
};

export default ShoppingCart;
