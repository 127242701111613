import styled from "styled-components";

export const Wrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.sp32};
`;

export const LoadingWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing.sp32};
`;

export const ResultActions = styled.div`
  display: flex;
  margin-top: 40px;
  flex-direction: column;
`;

export const ResultActionsPagination = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;
