import React, { Fragment } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import Header from "./components/Header/index";
import { useBasenamePath } from "./utils/useBasenamePath";
import { Main } from "./App.styled";
import ORingSelector from "./containers/ORingSelector";
import { NotificationCenter } from "./components/NotificationCenter";

const App: React.FC = () => {
  const basenamePath = useBasenamePath();

  return (
    <Router>
      <Fragment>
        <Header />
        <Main>
          <Switch>
            <Route exact path={basenamePath} component={ORingSelector} />
            <Redirect from="*" to={basenamePath} />
          </Switch>
        </Main>
        <NotificationCenter />
      </Fragment>
    </Router>
  );
};

export default App;
