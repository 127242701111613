import { IValues } from "./index";
import { OptionsList } from "../index";

type Error = { [key in keyof IValues]: string | undefined };

type ValidationStrings = {
  required: string;
  requiredOneOf: string;
  rangeField: string;
};

function validateRange(
  formValue: number,
  minValue: number,
  maxValue: number,
  startOfRangeText: string
) {
  if (!inRange(formValue, minValue, maxValue))
    return getValidationMessage(minValue, maxValue, startOfRangeText);
}

export const inRange = (num: number, start: number, end: number): boolean => {
  return num >= start && num <= end;
};

export const validation = (
  values: IValues,
  attributes: OptionsList,
  validationStrings: ValidationStrings,
  displayMetricsFields: boolean
) => {
  const errors: Error = {};

  if (!values.material && !values.compound) {
    errors.material = validationStrings.requiredOneOf;
    errors.compound = validationStrings.requiredOneOf;
  }

  if (displayMetricsFields) {
    if (!values.crossSection) errors.crossSection = validationStrings.required;
    if (!values.internalDiameter)
      errors.internalDiameter = validationStrings.required;

    errors.internalDiameter = validateRange(
      values.internalDiameter as number,
      attributes.internalDiameterMin as number,
      attributes.internalDiameterMax as number,
      validationStrings.rangeField
    );
    errors.crossSection = validateRange(
      values.crossSection as number,
      attributes.crossSectionMin as number,
      attributes.crossSectionMax as number,
      validationStrings.rangeField
    );
  } else {
    if (!values.oringSize) errors.oringSize = validationStrings.required;
  }
  return errors;
};

export const placeholderTextField = (
  minValue: number,
  maxValue: number
): string => {
  if (minValue && maxValue) {
    return " ≥ " + minValue + " & ≤ " + maxValue;
  }
  return "";
};

export const getValidationMessage = (
  minValue: number,
  maxValue: number,
  startOfRangeText: string
): string => {
  if (minValue && maxValue) {
    return startOfRangeText + placeholderTextField(minValue, maxValue);
  }
  return "";
};
