import React, { useContext, Fragment } from "react";
import { Box, Link, LoginContext } from "@eriksdigital/atomic-ui/components";
import { useIntl, FormattedMessage } from "react-intl";
import { GTMContext } from "@eriksdigital/gs-ga4";
import {
  Wrapper,
  Title,
  EriksNumber,
  TextWrapper,
  PriceBlock,
  NoPriceMessage,
  ItemDescription,
  ItemPrice,
  ItemInventory,
  ItemStock,
  TemperatureInfo,
  PriceWrapper,
  IconImage,
  ImageWrapper,
} from "./styles";
import { BREAKPOINTS_LABELS, useMedia } from "../../../../utils/useMedia";
import { StockInformation } from "../StockInformation";
import { AddToCart } from "../AddToCart";
import { Attribute, ItemData, Media } from "../index";

interface Props {
  data: ItemData;
  position: number;
  stockLoading: boolean;
}

const Item: React.FC<Props> = (props) => {
  const intl = useIntl();
  const { data, position, stockLoading } = props;
  const { isGuestUser, loginUrl, loggedIn } = useContext(LoginContext);
  const { sendSelectItemEvent } = useContext(GTMContext);
  const hasPrice = data.priceRaw && data.priceRaw > 0.00001;

  const isTablet = useMedia(BREAKPOINTS_LABELS.tabletLandscape);
  const priceInformation = () => {
    return data.price ? (
      <Fragment>
        <PriceWrapper
          data-testid="price"
          currentPrice={data.price}
          itemUnit=""
          perUnitLabel={intl.formatMessage({
            id: "results.priceLabel",
            defaultMessage: "Price (per piece)",
            description: "Price unit label",
          })}
          variant="medium"
        />
        {isTablet && !isGuestUser && (
          <Box>
            <AddToCart data={data} position={position} />
          </Box>
        )}
      </Fragment>
    ) : (
      <NoPriceMessage>
        <FormattedMessage
          id="results.noPriceMessage"
          defaultMessage="No price can be calculated for this item, please reach out to your contact person"
          description="Price unit label when price is unavailable"
        />
      </NoPriceMessage>
    );
  };

  const getMediasFromProduct = (medias?: Media[]) => {
    return medias?.find(
      (media) =>
        media.mediaType.toLowerCase().includes("product image") ||
        media.mediaType.toLowerCase().includes("item image")
    );
  };

  const getImageCertificationsFromProduct = (medias?: Media[]) => {
    return medias?.filter((media) =>
      media.mediaType.toLowerCase().includes("product icon")
    );
  };

  const getTemperatureRange = (attributes: Attribute[] | undefined) => {
    const temperatureRangeObject = attributes?.find(
      (attribute) =>
        attribute.featureName ===
        intl.formatMessage({
          id: "feature.temperatureRange",
          defaultMessage: "Temperature range",
          description: "Temperature range",
        })
    );

    if (!temperatureRangeObject) {
      return "";
    }

    return `${temperatureRangeObject.featureName} (°C) ${temperatureRangeObject.value}`;
  };

  const handleOnClickItem = (event: MouseEvent) => {
    event.preventDefault();
    sendSelectItemEvent &&
      sendSelectItemEvent({
        itemName: data.description,
        price: data.priceRaw,
        itemId: data.partNumber,
        itemBrand: data.brand,
        itemStockStatus: data.stockStatus.available,
        index: position,
        materialNumber: data.erpPartNumber,
        isLoggedIn: loggedIn,
      });
    window.open(data.itemLink, "_blank");
  };

  return (
    <Wrapper>
      <ItemDescription>
        <Box width="100px">
          <ImageWrapper
            src={getMediasFromProduct(data.medias)?.fullImage || ""}
          />
        </Box>
        <TextWrapper>
          <Link
            data-testid={`oring-result-${data.erpPartNumber}-link`}
            href={data.itemLink}
            onClick={handleOnClickItem}
          >
            <Title data-testid="description">{data.description}</Title>
          </Link>
          <Box marginTop="sp12">
            <EriksNumber data-testid="basicItemNumber">
              <FormattedMessage
                id="results.eriksNumber"
                defaultMessage="ERIKS Item:"
                description="article number label"
              />{" "}
              {data.erpPartNumber}
            </EriksNumber>
            <TemperatureInfo
              data-testid={`oring-result-${data.erpPartNumber}-temperature`}
            >
              {getTemperatureRange(data.attributes)}
            </TemperatureInfo>
            <Box
              data-testid={`oring-result-${data.erpPartNumber}-certificates`}
            >
              {getImageCertificationsFromProduct(data.medias)?.map(
                (c: Media) => (
                  <IconImage
                    key={c.mediaType}
                    src={c?.fullImage || ""}
                    alt=""
                  />
                )
              )}
            </Box>
          </Box>
        </TextWrapper>
      </ItemDescription>
      <ItemInventory>
        <ItemStock>
          <StockInformation
            stockLoading={stockLoading}
            stockStatus={data.stockStatus}
          />
        </ItemStock>
        <ItemPrice>
          {isGuestUser ? (
            <PriceBlock>
              <Box>
                <Link
                  data-testid="loginForPrices"
                  alt="login for prices"
                  href={loginUrl}
                >
                  <FormattedMessage
                    id="login.priceLogin"
                    defaultMessage="Please login to see prices"
                    description="Price ewhen user is not logged in"
                  />
                </Link>
              </Box>
            </PriceBlock>
          ) : (
            priceInformation()
          )}
          {!isTablet && !isGuestUser && hasPrice && (
            <Box width="100%">
              <AddToCart data={data} position={position} />
            </Box>
          )}
        </ItemPrice>
      </ItemInventory>
    </Wrapper>
  );
};

export default Item;
