import gql from "graphql-tag";

export const GET_O_RING_ATTRIBUTES = gql`
  query getORingAttributes(
    $lang: String!
    $erpSystem: String!
    $material: String
    $compound: String
    $hardness: String
    $approvals: [String]
    $colour: String
    $oringSize: String
  ) {
    getORingAttributes(
      lang: $lang
      erpSystem: $erpSystem
      material: $material
      compound: $compound
      hardness: $hardness
      approvals: $approvals
      colour: $colour
      oringSize: $oringSize
    ) {
      compound {
        value
      }
      material {
        value
      }
      approval {
        value
      }
      hardness {
        value
      }
      colour {
        value
      }
      as568Size {
        value
        internalDiameter
        crossSection
      }
      bs4518Size {
        value
        internalDiameter
        crossSection
      }
      din11864Size {
        value
        internalDiameter
        crossSection
      }
      jisSize {
        value
        internalDiameter
        crossSection
      }
      frenchRSize {
        value
        internalDiameter
        crossSection
      }
      crossSectionMin
      crossSectionMax
      internalDiameterMax
      internalDiameterMin
    }
  }
`;

export const IS_LOGGED_IN = gql`
  query isLoggedIn(
    $languageId: Int!
    $erpSystem: String!
    $targetSystem: String!
  ) {
    isLoggedIn(
      languageId: $languageId
      erpSystem: $erpSystem
      targetSystem: $targetSystem
    ) {
      loginUrl
      loggedIn
      isGuestUser
      userType
      userId
      userSegment
    }
  }
`;
