import styled from "styled-components";
import { Box } from "@eriksdigital/atomic-ui/components";

export const LabelWrapper = styled.span`
  font-weight: bold;
`;

export const ButtonWrapper = styled(Box)`
  margin: 0 0 9rem 0;
  display: inline-block;
`;
