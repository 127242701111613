import { useFrame } from "@react-three/fiber";

const Zoomer = ({ zoom }: { zoom: number }) => {
  useFrame((state) => {
    state.camera.zoom = zoom;
    state.camera.fov = zoom / 2;
    state.camera.updateProjectionMatrix();
  });
  return null;
};

export { Zoomer };
