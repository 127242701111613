import gql from "graphql-tag";

export const GET_STOCK_LEVEL = gql`
  query getStockLevels($erpSystem: String, $itemsNumbers: [String]) {
    getStockLevels(erpSystem: $erpSystem, itemsNumbers: $itemsNumbers) {
      available
      quantity
      deliveryDate
      number
    }
  }
`;
