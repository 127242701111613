import styled from "styled-components";

interface LanguagePanelProps {
  menuOpen: boolean;
}

export const HeaderWrapper = styled.div`
  width: 100%;
  background-color: #fff;
  height: 64px;
  border-bottom: 1px solid #c8ccd6;
`;

export const HeaderInnerWrapper = styled.div`
  width: 100%;
  max-width: 75em;
  margin: 0 auto;
`;

export const HeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.9375rem;
`;

export const LogoWrapper = styled.a`
  margin-top: 1em;
  height: 64px;
  display: flex;
  margin-left: -3em;
  > div {
    display: flex;
  }
`;

export const MenuPanelContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const LanguagePanel = styled.div<LanguagePanelProps>`
  position: relative;
  width: 200px;
  top: 10px;
  z-index: 1;
  height: auto;
  transform: scaleY(${(props) => (props.menuOpen ? "1" : "0")});
  overflow: hidden;
  background-color: white;
  padding: 20px;
  border: ${(props) => (props.menuOpen ? "1px solid #dedede" : "0")};
  opacity: ${(props) => (props.menuOpen ? "1" : "0")};
  transition: transform 0.1s, opacity 0.3s;
`;

export const StyledSelect = styled.select`
  border: 1px solid #d2d0d0;
  padding: 0.5rem;
  padding-right: 25px;
  min-width: 100%;
  border-radius: 2px;
  background: transparent;
  background-image: none;
  appearance: none;
  &:focus {
    border: 1px solid rgba(0, 92, 169, 1);
  }
`;
