import React, { useContext } from "react";
import { Button, LoginContext } from "@eriksdigital/atomic-ui/components";
import { FormattedMessage } from "react-intl";

const LogInStatus: React.FC = () => {
  const { isGuestUser, loginUrl, loginLoading } = useContext(LoginContext);

  if (loginLoading) return null;

  return (
    <div>
      {!isGuestUser ? (
        <FormattedMessage
          id="login-status.logged-in"
          defaultMessage="Logged in"
          description="status of loged in user"
        />
      ) : (
        <Button variant="ghost" id="login" as="a" href={loginUrl}>
          <FormattedMessage
            id="login-status.go-to-login"
            defaultMessage="Logged in"
            description="Login for prices"
          />
        </Button>
      )}
    </div>
  );
};

export default LogInStatus;
