import { Result } from "../containers/ORingSelector";
import {
  FormItem,
  IValues,
} from "../containers/ORingSelector/ORingSelectorForm";

const transformOringSize = (values: IValues) => {
  const encode = (obj: {}) => {
    return JSON.stringify(obj);
  };

  if (values.oringSize?.value) {
    return encode({
      type: "single_value",
      standard: values.oringSize.searchCode,
      size: values.oringSize.value,
    });
  } else if (values.crossSection || values.internalDiameter) {
    return encode({
      type: "metric",
      crossSection: Number(values.crossSection),
      internalDiameter: Number(values.internalDiameter),
    });
  }
};

const transformData = (values: IValues) => {
  const result: Result = {};
  const jsonRequest = ["internalDiameter", "crossSection", "oringSize"];
  Object.keys(values).forEach((item) => {
    if (Array.isArray(values[item])) {
      result[item] = values[item].map(
        (valueItem: FormItem) => valueItem?.value
      );
    } else if (
      typeof values[item] === "object" &&
      values[item] !== null &&
      values[item].value !== null &&
      !jsonRequest.includes(item)
    ) {
      result[item] = values[item]?.value;
    }
  });

  const oringSize = transformOringSize(values);

  if (oringSize) result["oringSize"] = oringSize;

  return result;
};

export { transformData, transformOringSize };
