import { Mutator } from "final-form";

export const mutators: { [key: string]: Mutator } = {
  setPositiveValue: ([event], state, { changeValue }) => {
    const value = event.target.value;
    if (value >= 0) {
      changeValue(state, event.target.name, () => value);
    }
    if (!value) {
      changeValue(state, event.target.name, () => "");
    }
  },
  setValue: ([field, value], state, { changeValue }) => {
    changeValue(state, field, () => value);
  },
};
