import gql from "graphql-tag";

export const GET_O_RINGS_ITEMS = gql`
  query getORingItems(
    $lang: String!
    $erpSystem: String!
    $material: String
    $compound: String
    $hardness: String
    $limit: Int
    $sort: String
    $sortDirection: String
    $approvals: [String]
    $colour: String
    $oringSize: String
  ) {
    getORingItems(
      lang: $lang
      erpSystem: $erpSystem
      material: $material
      compound: $compound
      hardness: $hardness
      approvals: $approvals
      oringSize: $oringSize
      limit: $limit
      sort: $sort
      sortDirection: $sortDirection
      colour: $colour
    ) {
      items {
        brand
        description
        englishDescription
        erpPartNumber
        itemLink
        partNumber
        price
        priceRaw
        medias {
          mediaType
          fullImage
        }
        attributes {
          featureName
          value
        }
      }
      hasMore
    }
  }
`;
