import { createGlobalStyle } from "styled-components";
import { defaultTheme, fontFace } from "@eriksdigital/atomic-ui/styles";

export type Theme = typeof defaultTheme;

export interface ThemeProps {
  theme: Theme;
}

export const GlobalStyles = createGlobalStyle`
  ${fontFace}

  * {
    box-sizing: border-box;
  }

  body {
    font-family: geomanist;
    font-weight: 400;
  }

  body,
  p,
  ul,
  h1,
  h2,
  h3,
  h4 {
    margin: 0;
    padding: 0;
  }

  ul {
    list-style-type: none;
  }

  select,
  input {
    outline: none;
  }

  label > svg {
    margin-left: 2px;
  }

  select::-ms-expand {
    visibility: hidden;
  }
  
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}
`;

export default GlobalStyles;
