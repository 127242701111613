import React, { useContext, useEffect, useRef, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import {
  LanguageContext,
  Logo,
  Box,
  LoginContext,
} from "@eriksdigital/atomic-ui/components";
import {
  HeaderWrapper,
  HeaderInnerWrapper,
  HeaderInner,
  LogoWrapper,
  MenuPanelContainer,
  LanguagePanel,
  StyledSelect,
} from "./style";
import { ENVIRONMENT } from "../../constants";
import { constructUrl, EnvironmentsType } from "../../utils/shopUtils";
import Hamburger from "../Hamburger";
import ShoppingCart from "../ShoppingCart";
import { useBasenamePath } from "../../utils/useBasenamePath";
import { GET_AVAILABLE_LANGUAGES } from "./queries";
import {
  LocalesDropdownItem,
  pimLocalesDropdown,
} from "../../utils/languageUtils";
import { GetAvailableLanguages } from "./schema/GetAvailableLanguages";
import LogInStatus from "../LogInStatus";

const Header: React.FC = () => {
  const { language, setLanguage, erpSystem } = useContext(LanguageContext);
  const [isMenuOpen, toggleMenu] = useState(false);
  const menu = useRef<HTMLDivElement>(null);
  const [languageDropdown, updateLanguageDropdown] = useState<
    LocalesDropdownItem[]
  >(Object.values(pimLocalesDropdown));
  const basenamePath = useBasenamePath();

  const history = useHistory();

  const { loggedIn } = useContext(LoginContext);

  const { data } = useQuery<GetAvailableLanguages>(GET_AVAILABLE_LANGUAGES, {
    variables: {
      erpSystem: erpSystem.toUpperCase(),
    },
  });
  const availableLanguages = data && data.getAvailableLanguages;

  useEffect(() => {
    if (availableLanguages) {
      const newDropdown = availableLanguages.map(
        (lang) => pimLocalesDropdown[lang.countryCode]
      );
      updateLanguageDropdown(newDropdown);
      if (!newDropdown.find((locale) => locale.value === language)) {
        setLanguage(newDropdown[0].value);
      }
    }
  }, [language, availableLanguages, setLanguage]);

  const handleChangeLanguage = ({
    target: { value },
  }: React.ChangeEvent<HTMLSelectElement>) => {
    history.push(basenamePath);
    setLanguage(value);
  };

  const handleMenuToggle = (): void => toggleMenu(!isMenuOpen);

  const handleClickOutside = (e: MouseEvent): void => {
    if (menu.current && menu.current.contains(e.target as HTMLElement)) {
      return;
    }

    toggleMenu(false);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => document.removeEventListener("click", handleClickOutside);
  }, [isMenuOpen]);

  return (
    <HeaderWrapper data-testid="header">
      <HeaderInnerWrapper>
        <HeaderInner>
          <LogoWrapper
            href={`${constructUrl(
              ENVIRONMENT as EnvironmentsType,
              erpSystem,
              language
            )}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Logo inverted={null} width="sz208" height="64" />
          </LogoWrapper>
          <Box display="flex" alignItems="center">
            {loggedIn && <ShoppingCart />}
            <Hamburger
              id="menu-button"
              onClick={handleMenuToggle}
              open={isMenuOpen}
            />
            <LogInStatus />
          </Box>
        </HeaderInner>
        <MenuPanelContainer id="menu" ref={menu}>
          <LanguagePanel data-testid="menu-panel" menuOpen={isMenuOpen}>
            <StyledSelect
              data-testid="language-select"
              onChange={handleChangeLanguage}
              value={language}
            >
              {languageDropdown.map((lang) => {
                return (
                  <option value={lang.value} key={lang.value}>
                    {lang.label}
                  </option>
                );
              })}
            </StyledSelect>
          </LanguagePanel>
        </MenuPanelContainer>
      </HeaderInnerWrapper>
    </HeaderWrapper>
  );
};

export default Header;
