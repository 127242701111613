import styled from "styled-components";

interface ItemCountCircleProps {
  isLargeAmount: boolean;
}

export const IconWrapper = styled.span`
  display: inline-block;
  height: 2.25rem;
  line-height: 2.25rem;
  position: relative;
  text-align: center;
  width: 2.25rem;
  margin-right: ${({ theme }) => theme.spacing.sp8};

  &:hover {
    cursor: pointer;
  }
`;

export const ItemCountCircle = styled.div<ItemCountCircleProps>`
  background-color: ${({ theme }) => theme.colors.default.orange};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.heading.primary};
  font-size: 0.6875rem;
  height: ${({ isLargeAmount }) => (isLargeAmount ? "1.125rem" : "0.875rem")};
  line-height: ${({ isLargeAmount }) => (isLargeAmount ? "18px" : "14px")};
  position: absolute;
  right: ${({ isLargeAmount }) => (isLargeAmount ? "0.1875rem" : 0)};
  text-align: center;
  top: 0;
  width: ${({ isLargeAmount }) => (isLargeAmount ? "1.375rem" : "0.875rem")};
`;

IconWrapper.displayName = "IconWrapper";
