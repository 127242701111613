import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing.sp16};
`;

export const ContainerWrapper = styled.div`
  border: none;
  margin-bottom: ${({ theme }) => theme.spacing.sp16};
  background-color: ${({ theme }) => theme.colors.background.tertiary};
  border-radius: 14px;
  padding: 0 0.5em;
`;
