import gql from "graphql-tag";

export const GET_AVAILABLE_LANGUAGES = gql`
  query GetAvailableLanguages($erpSystem: String!) {
    getAvailableLanguages(erpSystem: $erpSystem) {
      countryCode
      countryName
    }
  }
`;

export const IS_LOGGED_IN = gql`
  query isLoggedIn(
    $languageId: Int!
    $erpSystem: String!
    $targetSystem: String!
  ) {
    isLoggedIn(
      languageId: $languageId
      erpSystem: $erpSystem
      targetSystem: $targetSystem
    ) {
      loginUrl
      loggedIn
      isGuestUser
    }
  }
`;
