import React, { ReactNode } from "react";
import { FieldRenderProps } from "react-final-form";

import { ReactSelect } from "@eriksdigital/atomic-ui/components";

import ColorPalette from "../ColorPalette";
import { TextFieldWrapper } from "./styles";

interface RenderOption {
  label: string;
  value: string;
}

interface FormAdapterProps
  extends FieldRenderProps<string | number, HTMLElement> {
  id?: string;
  submitting?: boolean;
  component?: ReactNode;
  label?: string;
}

interface SelectAdapterProps extends FormAdapterProps {
  renderOptions(option: string): RenderOption;
}

interface ColorAdapterProps extends FormAdapterProps {
  colors: string[];
}

export const TextFieldAdapter: React.FC<FormAdapterProps> = ({
  input,
  meta,
  submitting,
  component,
  ...rest
}) => (
  <TextFieldWrapper
    {...input}
    {...rest}
    value={String(input.value)}
    error={meta.touched && meta.invalid}
    errorMessage={(meta.touched && meta.error) || ""}
  />
);

export const SelectAdapter: React.FC<SelectAdapterProps> = ({
  input,
  submitting,
  meta,
  ...rest
}) => (
  <ReactSelect
    {...input}
    {...rest}
    classNamePrefix="Select"
    error={meta.touched && meta.invalid}
    errorMessage={meta.touched && meta.error}
  />
);

export const ColorAdapter: React.FC<ColorAdapterProps> = ({
  input,
  meta,
  ...rest
}) => {
  return (
    <ColorPalette
      {...input}
      {...rest}
      selected={input.value as any}
      error={meta && meta.touched && meta.invalid}
      errorMessage={meta && meta.touched && meta.error}
    />
  );
};
