import React, { useCallback, useContext, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import {
  Box,
  Icon,
  LanguageContext,
  PopupHost,
  Link,
  Divider,
  GridColumn,
} from "@eriksdigital/atomic-ui/components";
import { GTMContext } from "@eriksdigital/gs-ga4";
import { TooltipIcon } from "@eriksdigital/atomic-ui/components/Icons";

import { inRange, placeholderTextField, validation } from "./validate";
import { mutators } from "./mutators";
import {
  ColorAdapter,
  SelectAdapter,
  TextFieldAdapter,
} from "../../../components/FormAdapters/FormAdapters";
import { Preview3d, PreviewProps } from "./Preview3d";

import {
  ActionButton,
  ActionContainer,
  FormContainer,
  PreLine,
  LabelWrapper,
  OrParagraph,
  Row,
  FormBox,
  TopFormBox,
  BottomBoxForm,
  InputWrapper,
  HideOnMobile,
  HideOnDesktop,
  ButtonsWrapper,
  InnerWrapper,
  CrossSection,
  Pointer,
  ClearLink,
  FormColumn,
  PreviewColumn,
  LabelColumn,
  MaterialColumn,
  BottomColumn,
  PreviewContainer3d,
} from "./styles";
import { Attribute, OptionsList, Result } from "../index";
import SizeSelectionModal, {
  getSearchCode,
  getSizeLabel,
} from "../../../components/SizeSelectionModal";
import { transformData } from "../../../utils/json-helpers";
import { FormattedMessage, useIntl } from "react-intl";

interface Props {
  attributes: OptionsList;
  onSubmit(form: IValues): void;
  getAttributes(vars: Variables): void;
  showNoResults: (submitFailed: boolean, formData: IValues) => void;
  resetForm: () => void;
}

interface Variables {
  variables: { [key: string]: string | number };
}

export type FormItem = {
  id: string;
  label: string;
  value: string;
  searchCode?: string;
} | null;

export type SizeFormItem = FormItem & {
  crossSection: string;
  internalDiameter: string;
};

export interface IValues extends Result {
  material?: FormItem;
  compound?: FormItem;
  hardness?: FormItem;
  approvals?: [FormItem] | null;
  colour?: FormItem;
  crossSection?: number;
  internalDiameter?: number;
  oringSize?: SizeFormItem;
}

export interface SelectionOptions {
  label: string;
  value: string;
  searchCode: string;
}

const METRIC_TYPE = "METRIC";

const ORingSelectorForm: React.FC<Props> = ({
  onSubmit,
  attributes,
  getAttributes,
  showNoResults,
  resetForm,
}) => {
  const { language, erpSystem } = useContext(LanguageContext);
  const { sendProcessFinishEvent } = useContext(GTMContext);
  const intl = useIntl();
  const [timeoutId, setTimeoutId] = useState<any>();
  const TIMEOUT = 1000;
  //Modal
  const [open, setOpen] = useState(false);
  const [sizeSelectionType, setSizeSelectionType] = useState(METRIC_TYPE);

  const initialValues: IValues = {
    material: null,
    compound: null,
    hardness: null,
    colour: null,
    approvals: null,
    internalDiameter: undefined,
    crossSection: undefined,
  };

  function throttle(fn: Function, wait: number) {
    return function (...args: any) {
      clearTimeout(timeoutId);
      setTimeoutId(
        setTimeout(() => {
          fn(...args);
          clearTimeout(timeoutId);
          setTimeoutId(null);
        }, wait)
      );
    };
  }

  const getAttributesByValue = (
    current: FormItem | number,
    prev?: FormItem | number
  ) => {
    if (
      (Array.isArray(current) &&
        JSON.stringify(current) !== JSON.stringify(prev)) ||
      // @ts-ignore
      (isNaN(current) ? current?.value !== prev?.value : current !== prev)
    ) {
      const values = window.Form.getState().values;
      const data = transformData(values);
      shouldGetNewData(Number(current), data, attributes) &&
        getAttributes({
          variables: {
            lang: language,
            erpSystem: erpSystem.toUpperCase(),
            ...data,
          },
        });
    }
  };

  const shouldGetNewData = (
    _current: number,
    data: Result,
    attributes: OptionsList
  ) => {
    let shouldReturnNewData = true;
    if (data.internalDiameter) {
      shouldReturnNewData = inRange(
        data.internalDiameter,
        attributes.internalDiameterMin as number,
        attributes.internalDiameterMax as number
      );
    }
    if (data.crossSection) {
      shouldReturnNewData = inRange(
        data.crossSection,
        attributes.crossSectionMin as number,
        attributes.crossSectionMax as number
      );
    }
    return shouldReturnNewData;
  };

  const setAttrsToFormAsync = useCallback(
    async () =>
      Object.keys(attributes).forEach((attr) => {
        const items = attributes[attr];
        if (Array.isArray(items) && items.length === 1) {
          window.Form.mutators.setValue(attr, {
            id: attr,
            label: items[0].value,
            value: items[0].value,
          });
        }
      }),
    [attributes]
  );

  const handleShowUnitMeasurementPopUp = () => {
    setOpen(true);
  };

  useEffect(() => {
    const setSelectValuesToForm = async () => {
      await setAttrsToFormAsync();
    };
    setSelectValuesToForm();
  }, [setAttrsToFormAsync]);

  //Modal events

  const onConfirm = (value: string) => {
    setSizeSelectionType(value);
    setOpen(false);
    window.Form.reset();
  };

  const onCancel = () => {
    setOpen(false);
  };
  //TODO move to effect that listens on state change
  const displayMetricsFields = (): boolean => {
    return sizeSelectionType === METRIC_TYPE;
  };

  function getPreviewValues(values: IValues) {
    const previewValues: PreviewProps = {
      colour: values?.colour?.value,
      internalDiameter: values.oringSize?.internalDiameter
        ? Number(values.oringSize.internalDiameter)
        : values.internalDiameter,
      crossSection: values.oringSize?.crossSection
        ? Number(values.oringSize.crossSection)
        : values.crossSection,
    };
    return previewValues;
  }

  function getArrayValues(attribute: Attribute[] | number) {
    if (attribute instanceof Array) {
      return attribute.map((attribute: Attribute) => attribute.value);
    } else {
      return null;
    }
  }

  const validationStrings = {
    required: intl.formatMessage({
      id: "form.requiredFieldText",
      defaultMessage: "The field is required",
      description: "Validation message for required input field",
    }),
    requiredOneOf: intl.formatMessage({
      id: "form.requiredOneOfFieldsText",
      defaultMessage: "Select material or compound",
      description: "Validation message for choosing either material or compund",
    }),
    rangeField: intl.formatMessage({
      id: "form.rangeFieldText",
      defaultMessage: "Type a value",
      description: "Start of range validation",
    }),
  };

  const sizeSelectionOptions: Array<SelectionOptions> = [
    {
      label: intl.formatMessage({
        id: "sizeLabel.as568Size",
        description: "label for as568Size size",
        defaultMessage: "AS568-BS1806-ISO3601",
      }),
      value: "as568Size",
      searchCode: "AS568",
    },
    {
      label: intl.formatMessage({
        id: "sizeLabel.bs4518Size",
        description: "label for BS 4518 size",
        defaultMessage: "BS 4518",
      }),
      value: "bs4518Size",
      searchCode: "BS4518",
    },
    {
      label: intl.formatMessage({
        id: "sizeLabel.din11864Size",
        description: "label forDIN 11864 size",
        defaultMessage: "DIN 11864",
      }),
      value: "din11864Size",
      searchCode: "DIN11864",
    },
    {
      label: intl.formatMessage({
        id: "sizeLabel.jisSize",
        description: "label JIS size",
        defaultMessage: "JIS",
      }),
      value: "jisSize",
      searchCode: "JIS",
    },
    {
      label: intl.formatMessage({
        id: "sizeLabel.metric",
        description: "label Metric size",
        defaultMessage: "Metric",
      }),
      value: "METRIC",
      searchCode: "METRIC",
    },
    {
      label: intl.formatMessage({
        id: "sizeLabel.frenchRSize",
        description: "label French R size",
        defaultMessage: "French R",
      }),
      value: "frenchRSize",
      searchCode: "FRENCHR",
    },
  ];
  return (
    <>
      <Form
        mutators={mutators}
        initialValues={initialValues}
        validate={(values) =>
          validation(
            values,
            attributes,
            validationStrings,
            displayMetricsFields()
          )
        }
        onSubmit={onSubmit}
        render={({ form, handleSubmit, values }) => {
          const submitHandler = () => {
            showNoResults(form.getState().submitFailed, form.getState().values);
            handleSubmit();
            sendProcessFinishEvent &&
              sendProcessFinishEvent({ processStepName: "2_showresult" });
          };
          window.Form = form;
          return (
            <FormColumn>
              <Row>
                <PreviewColumn desktop={4}>
                  <PreviewContainer3d display="flex" justifyContent="center">
                    <Preview3d {...getPreviewValues(values)} />
                  </PreviewContainer3d>
                </PreviewColumn>
                <FormColumn desktop={8}>
                  <FormBox width="100%">
                    <FormContainer>
                      <GridColumn>
                        <Box width="100%">
                          {displayMetricsFields() ? (
                            <TopFormBox>
                              <InnerWrapper display={"flex"} flex={"2"}>
                                <Box>
                                  <Field
                                    component={TextFieldAdapter}
                                    id="internalDiameter"
                                    name="internalDiameter"
                                    data-testid="internalDiameter"
                                    label={
                                      <LabelColumn>
                                        {intl.formatMessage({
                                          id: "form.internalDiameter",
                                          defaultMessage:
                                            "Internal diameter (mm)",
                                          description:
                                            "Label for internal diameter",
                                        })}
                                        <Pointer>
                                          <Box marginLeft="sp8">
                                            <Icon
                                              as={TooltipIcon}
                                              size="md"
                                              id="internalDiameter"
                                            />
                                          </Box>
                                        </Pointer>
                                      </LabelColumn>
                                    }
                                    placeholder={placeholderTextField(
                                      attributes.internalDiameterMin as number,
                                      attributes.internalDiameterMax as number
                                    )}
                                    onChange={form.mutators.setPositiveValue}
                                  />

                                  <PopupHost
                                    maxWidth="450"
                                    parent="internalDiameter"
                                  >
                                    <FormattedMessage
                                      id="tooltip.dimensions.internalDiameter"
                                      defaultMessage="O-rings are flexible products: the diameter can be streched (up to 5%) or compressed (up to 3%) when installed. Therefore, based on the dimensions you enter, the proposed results may slighly differ from you input, but can still be suitable for your needs."
                                      description="tooltip text for internal diameter"
                                    />
                                  </PopupHost>
                                  <OnChange name="internalDiameter">
                                    {throttle(getAttributesByValue, TIMEOUT)}
                                  </OnChange>
                                </Box>
                                <GridColumn desktop={1} />
                                <CrossSection flex="8">
                                  <Field
                                    component={TextFieldAdapter}
                                    data-testid="crossSection"
                                    id="crossSection"
                                    name="crossSection"
                                    label={
                                      <Box display={"flex"}>
                                        {intl.formatMessage({
                                          id: "form.crossSection",
                                          defaultMessage: "Cross section (mm)",
                                          description:
                                            "Label for cross section",
                                        })}
                                        <Pointer>
                                          <Box marginLeft="sp8">
                                            <Icon
                                              as={TooltipIcon}
                                              size="md"
                                              id="crossSection"
                                            />
                                          </Box>
                                        </Pointer>
                                      </Box>
                                    }
                                    placeholder={placeholderTextField(
                                      attributes.crossSectionMin as number,
                                      attributes.crossSectionMax as number
                                    )}
                                    onChange={form.mutators.setPositiveValue}
                                  />
                                  <PopupHost
                                    maxWidth="450"
                                    parent="crossSection"
                                  >
                                    <FormattedMessage
                                      id="tooltip.dimensions.crossSection"
                                      defaultMessage="O-rings are flexible products: the cross-section can be streched or compressed up to 0.03 mm when installed. Therefore, based on the dimensions you enter, the proposed results may slighly differ from you input, but can still be suitable for your needs."
                                      description="tooltip for cross section dimensions"
                                    />
                                  </PopupHost>
                                  <OnChange name="crossSection">
                                    {throttle(getAttributesByValue, TIMEOUT)}
                                  </OnChange>
                                </CrossSection>
                              </InnerWrapper>

                              <GridColumn desktop={4} />
                            </TopFormBox>
                          ) : (
                            <FormColumn desktop={8}>
                              <Field
                                component={SelectAdapter}
                                allowNull={true}
                                id={"oringSize"}
                                name={"oringSize"}
                                isClearable={true}
                                options={attributes[sizeSelectionType]}
                                label={getSizeLabel(
                                  sizeSelectionType,
                                  sizeSelectionOptions
                                )}
                                placeholder={intl.formatMessage({
                                  id: "form.selectPlaceholder",
                                  defaultMessage: "Type / select",
                                  description: "Select input placeholder",
                                })}
                                renderOptions={(option: Attribute) => ({
                                  label: option.value,
                                  value: option.value,
                                  searchCode: getSearchCode(
                                    sizeSelectionType,
                                    sizeSelectionOptions
                                  ),
                                  crossSection: option.crossSection,
                                  internalDiameter: option.internalDiameter,
                                })}
                              />
                              <OnChange name={"oringSize"}>
                                {getAttributesByValue}
                              </OnChange>
                            </FormColumn>
                          )}
                          <Box marginBottom="sp12">
                            <LabelWrapper
                              data-testid={`showUnitMeasurementButton`}
                              onClick={handleShowUnitMeasurementPopUp}
                            >
                              <FormattedMessage
                                id="form.needUnitMeasurement"
                                defaultMessage="Do you need a standard size?"
                                description="change size link"
                              />
                            </LabelWrapper>
                          </Box>

                          <HideOnDesktop>
                            <Divider />
                          </HideOnDesktop>
                          <HideOnDesktop>
                            <h5
                              style={{ fontWeight: 100, margin: "1em 0 1em" }}
                            >
                              <FormattedMessage
                                id="form.select"
                                defaultMessage="Select your compound"
                                description="select compound label"
                              />{" "}
                              <span style={{ fontWeight: "bold" }}>
                                <FormattedMessage
                                  id="form.paragraph.or"
                                  defaultMessage="Or"
                                  description="string between compound or material inputs"
                                />
                              </span>{" "}
                              {intl
                                .formatMessage({
                                  id: "form.material",
                                  defaultMessage: "Material",
                                  description: "material label",
                                })
                                .toLowerCase()}
                            </h5>
                          </HideOnDesktop>
                          <InputWrapper>
                            <MaterialColumn desktop="8">
                              <label id="materialLabel">
                                <LabelColumn>
                                  {intl.formatMessage({
                                    id: "form.material",
                                    defaultMessage: "Material",
                                    description: "material label",
                                  })}
                                  <Pointer>
                                    <Box marginLeft="sp8">
                                      <Icon
                                        as={TooltipIcon}
                                        size="md"
                                        id={"materialInfo"}
                                      />
                                    </Box>
                                  </Pointer>
                                </LabelColumn>
                              </label>
                              <Field
                                component={SelectAdapter}
                                allowNull={true}
                                id="material"
                                name="material"
                                aria-labelledby="materialLabel"
                                isClearable={true}
                                options={attributes.material}
                                placeholder={intl.formatMessage({
                                  id: "form.selectPlaceholder",
                                  defaultMessage: "Type / select",
                                  description: "Select input placeholder",
                                })}
                                renderOptions={(option: Attribute) => ({
                                  label: option.value,
                                  value: option.value,
                                })}
                              />
                              <PopupHost maxWidth="450" parent="materialInfo">
                                <FormattedMessage
                                  id="tooltip.material"
                                  defaultMessage="ERIKS has a broad range of rubber materials available. If you need help in selecting the correct material, our Chemical Resistance Guide can assist you. <link>https://o-ring.info/en/tools/chemical-resistance-guide/</link>"
                                  description="Tooltip for the material input"
                                  values={{
                                    link: (chunks: string) => (
                                      <Link
                                        alt="oring info"
                                        href={chunks}
                                        target="_blank"
                                      >
                                        {chunks}
                                      </Link>
                                    ),
                                  }}
                                />
                              </PopupHost>
                              <OnChange name="material">
                                {getAttributesByValue}
                              </OnChange>
                            </MaterialColumn>
                            <HideOnMobile flex="1">
                              <OrParagraph>
                                <FormattedMessage
                                  id="form.paragraph.or"
                                  defaultMessage="Or"
                                  description="string between compound or material inputs"
                                />
                              </OrParagraph>
                            </HideOnMobile>
                            <BottomColumn desktop="3">
                              <Box marginBottom="sp12">
                                <label id="compoundLabel">
                                  <LabelColumn>
                                    {intl.formatMessage({
                                      id: "form.compound",
                                      defaultMessage: "Compound",
                                      description: "Compound label",
                                    })}
                                    <Pointer>
                                      <Box marginLeft="sp8">
                                        <Icon
                                          as={TooltipIcon}
                                          size="md"
                                          id={"compoundInfo"}
                                        />
                                      </Box>
                                    </Pointer>
                                  </LabelColumn>
                                </label>
                                <Field
                                  component={SelectAdapter}
                                  allowNull={true}
                                  id="compound"
                                  name="compound"
                                  aria-labelledby="compoundLabel"
                                  isClearable={true}
                                  options={attributes.compound}
                                  placeholder={intl.formatMessage({
                                    id: "form.selectPlaceholder",
                                    defaultMessage: "Type / select",
                                    description: "Select input placeholder",
                                  })}
                                  renderOptions={(option: Attribute) => ({
                                    label: option.value,
                                    value: option.value,
                                  })}
                                />
                                <PopupHost maxWidth="450" parent="compoundInfo">
                                  <FormattedMessage
                                    id="tooltip.compound"
                                    defaultMessage="If you know what compound you need you can select it here, if you don't, you can complete the input for material, hardness and approval/compliances, and the selector will offer you o-rings made from suitable compounds."
                                    description="Tooltip for compound"
                                  />
                                </PopupHost>
                                <OnChange name="compound">
                                  {getAttributesByValue}
                                </OnChange>
                              </Box>
                            </BottomColumn>
                          </InputWrapper>
                          <HideOnDesktop>
                            <Divider />
                          </HideOnDesktop>
                          <BottomBoxForm>
                            <BottomColumn desktop="8">
                              <Box marginBottom="sp12" marginTop="sp12">
                                <Field
                                  component={SelectAdapter}
                                  allowNull={true}
                                  id="hardness"
                                  name="hardness"
                                  isClearable={true}
                                  options={attributes.hardness}
                                  label={
                                    <LabelColumn>
                                      {intl.formatMessage({
                                        id: "form.hardness",
                                        defaultMessage: "Hardness",
                                        description: "Label for hardness input",
                                      })}
                                      <Pointer>
                                        <Box marginLeft="sp8">
                                          <Icon
                                            as={TooltipIcon}
                                            size="md"
                                            id="hardnessInfo"
                                          />
                                        </Box>
                                      </Pointer>
                                    </LabelColumn>
                                  }
                                  placeholder={intl.formatMessage({
                                    id: "form.selectPlaceholder",
                                    defaultMessage: "Type / select",
                                    description: "Select input placeholder",
                                  })}
                                  renderOptions={(option: Attribute) => ({
                                    label: option.value,
                                    value: option.value,
                                  })}
                                />
                                <PopupHost maxWidth="450" parent="hardnessInfo">
                                  <FormattedMessage
                                    id="tooltip.hardness"
                                    defaultMessage="The default hardness is set to 70. Rubber O-rings with hardness 70 (except O-RIngs made from the material VMQ) can be used in pressurized applications up to 725 psi / 50 bar / 5MPa, considering a correct sealing gap dimension. At higher pressures, a higher hardness is recommended, sometimes in combination with a back-up ring."
                                    description="tooltip for hardness input"
                                  />
                                </PopupHost>
                                <OnChange name="hardness">
                                  {getAttributesByValue}
                                </OnChange>
                              </Box>
                              <Box marginBottom="sp12">
                                <label id="approvalsLabel">
                                  <LabelColumn>
                                    {intl.formatMessage({
                                      id: "form.approvals",
                                      defaultMessage: "Approvals",
                                      description: "Label for Approvals input",
                                    })}
                                    <Pointer>
                                      <Box marginLeft="sp8">
                                        <Icon
                                          as={TooltipIcon}
                                          size="md"
                                          id="approvalsInfo"
                                        />
                                      </Box>
                                    </Pointer>
                                  </LabelColumn>
                                </label>
                                <Field
                                  id="approvals"
                                  name="approvals"
                                  aria-labelledby="approvalsLabel"
                                  allowNull={true}
                                  isSearchable={false}
                                  isClearable
                                  isMulti
                                  withCheckBox
                                  component={SelectAdapter}
                                  options={attributes.approval}
                                  placeholder={intl.formatMessage({
                                    id: "form.selectPlaceholderOneOrMore",
                                    defaultMessage: "Select 1 or more",
                                    description:
                                      "placeholder for multiple select",
                                  })}
                                  renderOptions={(option: Attribute) => ({
                                    label: option.value,
                                    value: option.value,
                                  })}
                                />
                                <PopupHost
                                  maxWidth="450"
                                  parent="approvalsInfo"
                                >
                                  <PreLine>
                                    <FormattedMessage
                                      id="tooltip.approvals"
                                      defaultMessage="In some cases (e.g. food, pharma and potable water) it is required to use O-rings made from compounds that are tested or certified for use in a specific industry. Therefore you can select here your specific aprovals/compliances. Find here an overview of the most common Approvals/Compliances:\n\n •\t3-A: dairy industry (in case EC1935 and/or FDA is not sufficient)\n•\tACS: drinking water for French market\n•\tADI Free: free of Animal Derived Ingredients\n•\tAfPS GS (PAH): limitations related to PAK/PAH\n•\tChinese Food GB regulations\n•\tEC1935: food industry in European market\n•\tEN14241: flue gas systems\n•\tEN549: gas distribution\n•\tEN681: drinking water distribution \n•\tEN682: gas distribution\n•\tFDA: food industry in USA market, often globally accepted\n•\tISO 10993: biocompatibility for pharmaceutical industry\n•\tKIWA: drinking water for Dutch market\n•\tKTW: drinking water for German market\n•\tNACE: oil & gas industry with AED/RGD test protocol\n•\tNorsok: oil & gas industry with AED/RGD test protocol\n•\tNSF61: drinking water for USA market\n•\tUBA: drinking water for German market\n•\tUSP: pharmaceutical industry in USA market, often globally accepted\n•\tW270: drinking water for German market\n•\tWRAS: drinking water for UK market."
                                      description="Tooltip for approvals input"
                                    />
                                  </PreLine>
                                </PopupHost>
                                <OnChange name="approvals">
                                  {getAttributesByValue}
                                </OnChange>
                              </Box>
                              <Box marginTop="sp16">
                                <Field
                                  name="colour"
                                  component={ColorAdapter}
                                  colors={getArrayValues(attributes.colour)}
                                />
                                <OnChange name="colour">
                                  {getAttributesByValue}
                                </OnChange>
                              </Box>
                            </BottomColumn>
                          </BottomBoxForm>
                        </Box>
                      </GridColumn>
                    </FormContainer>
                    <ButtonsWrapper width="full" display="flex">
                      <ActionContainer>
                        <HideOnMobile>
                          <ActionButton
                            id="resetFields"
                            variant="secondary"
                            data-testid="resetFields"
                            stretch="auto"
                            onClick={resetForm}
                          >
                            <FormattedMessage
                              id="form.clearAllFields"
                              defaultMessage="Clear all fields"
                              description="clear all fields label"
                            />
                          </ActionButton>
                        </HideOnMobile>
                        <HideOnDesktop>
                          <div style={{ marginBottom: "10px" }}>
                            <ClearLink onClick={resetForm}>
                              <FormattedMessage
                                id="form.clearAllFields"
                                defaultMessage="Clear all fields"
                                description="clear all fields label"
                              />
                            </ClearLink>
                          </div>
                        </HideOnDesktop>
                        <ActionButton
                          id="showResults"
                          data-testid="showResults"
                          type="submit"
                          stretch="auto"
                          onClick={submitHandler}
                        >
                          <FormattedMessage
                            id="form.showResults"
                            defaultMessage="Show results"
                            description="show results label"
                          />
                        </ActionButton>
                      </ActionContainer>
                    </ButtonsWrapper>
                  </FormBox>
                </FormColumn>
              </Row>
            </FormColumn>
          );
        }}
      />

      <SizeSelectionModal
        open={open}
        onCancel={onCancel}
        value={sizeSelectionType}
        onConfirm={onConfirm}
        sizeOptions={sizeSelectionOptions}
      />
    </>
  );
};

export default ORingSelectorForm;
