import styled from "styled-components";

import { Divider, Box } from "@eriksdigital/atomic-ui/components";

export const DividerWrapper = styled(Divider)`
  height: 1px;
  flex: 1 0 auto;
`;

export const LabelWrapper = styled.span`
  margin: 0 ${({ theme }) => theme.spacing.sp12};
  color: ${({ theme }) => theme.colors.default.blueD};
`;

export const ShowMoreButton = styled(Box)`
  cursor: pointer;
`;
