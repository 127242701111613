import styled from "styled-components";
import { P, Box } from "@eriksdigital/atomic-ui/components";
import { breakpoints } from "@eriksdigital/atomic-ui/styles";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.sp16};

  border-top: 1px solid ${({ theme }) => theme.colors.border.gray};

  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.gray};
  }
`;

export const NoResultHeading = styled(P)`
  font-size: 1.2em;
  margin-bottom: 1em;
`;

export const List = styled.ul`
  list-style-type: disc;
  margin-bottom: 1em;
  margin-left: 1.3em;
  display: "flex";
`;

export const ContactItem = styled(Box)`
  svg {
    margin-top: 3px;
    margin-right: 5px;
  }
  p {
    display: inline;
  }
`;

export const SorryContainer = styled.div`
  ${breakpoints.phone`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  `}
`;

export const IconContainer = styled.div`
  flex: 1 1 100%;
  display: flex;
  align-items: center;

  svg {
    ${breakpoints.phone`
      flex: 0 0 20%;
    `}
    ${breakpoints.phoneLarge`
      flex: 0 0 10%;
    `}
  }
  h4 {
  }
`;

export const NoResultsTextWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  ${breakpoints.phoneLarge`
    flex: 0 0 80%;
    margin-left: 10%;
  `}
`;
