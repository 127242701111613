import React from "react";
import { FormattedMessage } from "react-intl";
import { StockIndicator } from "@eriksdigital/atomic-ui/components";
import { DeliveryDate } from "./style";

export type statuses = {
  CompletelyOnStock: string;
  NothingOnStock: string;
  Loading: string;
  Unavailable: string;
};

const statusesType = {
  CompletelyOnStock: {
    status: "inStock",
    message: (
      <FormattedMessage
        id="stock-info.CompletelyOnStock"
        defaultMessage="In stock"
        description="Stock availability - completely on stock"
      />
    ),
  },
  NothingOnStock: {
    status: "outOfStock",
    message: (
      <FormattedMessage
        id="stock-info.NothingOnStock"
        defaultMessage="Out of stock"
        description="Stock availability - out of stock"
      />
    ),
  },
  Loading: {
    status: "loading",
    message: (
      <FormattedMessage
        id="stock-info.loading"
        defaultMessage="Loading stock information"
        description="Stock availability - loading"
      />
    ),
  },
  Unavailable: {
    status: "infoUnavailable",
    message: (
      <FormattedMessage
        id="stock-info.unavailable"
        defaultMessage="Stock information unavailable"
        description="Stock availability - unavailable"
      />
    ),
  },
};

export const StockInformation = ({
  stockStatus,
  stockLoading,
}: {
  stockStatus: { available: keyof statuses; deliveryDate: string };
  stockLoading: boolean;
}) => {
  const { available, quantity, deliveryDate } = {
    available: stockStatus?.available || "Unavailable",
    quantity: 0,
    deliveryDate: stockStatus?.deliveryDate,
  };

  const status: keyof statuses = stockLoading ? "Loading" : available;
  return (
    !!status &&
    (status === "NothingOnStock" ? (
      <DeliveryDate>
        <FormattedMessage
          id="stock-info.deliveryDate"
          defaultMessage="Estimated delivery date"
          description=""
        />{" "}
        {deliveryDate}
      </DeliveryDate>
    ) : (
      <StockIndicator
        quantity={quantity}
        status={statusesType[status]?.status}
        stockMessage={statusesType[status]?.message}
      />
    ))
  );
};
