import styled from "styled-components";
import { translatedColorsMap } from "./colorsMap";
import { Language } from "../../utils/languageUtils";
import { Box } from "@eriksdigital/atomic-ui/components";

interface ColorProps {
  title: string;
  color?: string;
  language?: Language;
  isSelected: boolean;
  name: string;
}

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const ColorLabel = styled(Box)`
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
`;

export const Color = styled.button<ColorProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.grays.gray};
  margin-top: ${({ theme }) => theme.spacing.sp4};
  cursor: pointer;
  &:focus {
    outline: none;
  }
  ${({ theme, color, language }) => {
    if (
      color === "Amber/barnsteen (transparant)" ||
      color === "Amber (translucent)" ||
      color === "Amber/Bernstein (lichtdurchlässig)"
    ) {
      return `
        background-image:
        linear-gradient(45deg, ${theme.colors.grays.gray} 25%, transparent 25%), 
        linear-gradient(135deg, ${theme.colors.grays.gray} 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, ${theme.colors.grays.gray} 75%),
        linear-gradient(135deg, transparent 75%, ${theme.colors.grays.gray} 75%);
        background-size: 10px 10px; /* Must be a square */
        background-position:0 0, 5px 0, 5px -5px, 0px 5px; /* Must be half of one side of the square */
      `;
    }
    if (
      color === "Clear" ||
      color === "Helder" ||
      color === "Klar" ||
      color === "Clair"
    ) {
      return `
        background-image:
        linear-gradient(to right, rgba(192, 192, 192, 0.75), rgba(192, 192, 192, 0.75)),                    
        linear-gradient(to right, black 50%, white 50%),
        linear-gradient(to bottom, black 50%, white 50%);
        background-blend-mode: normal, difference, normal;
        background-size: 10px 10px;
      `;
    }
    return `background: ${
      (color &&
        language &&
        translatedColorsMap[language] &&
        translatedColorsMap[language][color] &&
        translatedColorsMap[language][color]) ||
      theme.colors.background.gray
    };`;
  }}
  ${({ theme, isSelected }) =>
    isSelected && `border: 4px solid ${theme.colors.default.blueB}`};
  margin-right: ${({ theme }) => theme.spacing.sp8};

  &:last-child {
    margin-right: 0;
  }
`;

export const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.colors.text.alert};
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  margin-top: ${({ theme }) => theme.spacing.sp8};
  display: block;
`;
