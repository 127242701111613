import { breakpoints } from "@eriksdigital/atomic-ui/styles";
import {
  Button,
  Box,
  Link,
  GridColumn,
  GridRow,
} from "@eriksdigital/atomic-ui/components";
import styled from "styled-components";
import { Theme } from "../../../styles/GlobalStyles";

export const FormColumn = styled(GridColumn)`
  margin: 0;
  padding: 0;
`;

export const LabelColumn = styled(GridColumn)`
  display: flex;
  margin: 0;
  padding: 0;
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
`;

export const Row = styled(GridRow)`
  padding: 0;
`;

export const PreviewColumn = styled(GridColumn)`
  margin: 0;
  background-color: white;
  border-radius: 14px;
  width: 30%;
  ${breakpoints.phone`
      display: none;
  `};

  ${breakpoints.tabletLandscape`
      display: block;
  `};
`;

export const FormContainer = styled.form`
  ${breakpoints.tabletLandscape`
      padding: 1em;`}
`;

export const ActionContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 1em;

  ${breakpoints.phone`
      flex-direction: row-reverse;
      width: 100%;
      padding: 0 1em 1em;
  `}

  ${breakpoints.tabletLandscape`
      flex-direction: row;
      width: auto;
      padding-bottom: 1em;
  `}
`;

export const ButtonsWrapper = styled(Box)`
  ${breakpoints.tabletLandscape`
      margin-top: -2em;
      justify-content: flex-end;
  `}
`;

export const ActionButton = styled(Button)`
  ${breakpoints.tabletLandscape`
      margin: 0 ${({ theme }: Theme) => theme.spacing.sp4};
      width: auto;
  `}
  ${breakpoints.phone`
      flex: 1;
      width: 100%;
  `}
`;

export const PreLine = styled.div`
  white-space: pre-line;
`;

export const OrParagraph = styled.p`
  text-align: center;
  margin-top: 2.4rem;
  color: ${({ theme }) => theme.colors.border.gray};
`;

export const LabelWrapper = styled.span`
  color: ${({ theme }) => theme.colors.text.secondary};
  cursor: pointer;
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
`;

export const Wrapper = styled.main`
  max-width: 75em;
  margin: 0px auto;
  padding: 0 ${({ theme }) => theme.spacing.sp16};
`;

export const PreviewBox = styled(Box)`
  ${breakpoints.phone`
      display: none;
      background-color: white;
      border-radius: 14px;
  `}

  ${breakpoints.tabletLandscape`
      display: block;
  `}
`;

export const FormBox = styled(Box)`
  background-color: white;
  border-radius: 14px;
  ${breakpoints.phone`
    padding: 0.5em;
  `};
`;

export const TopFormBox = styled(Box)`
  ${breakpoints.phone`
      display: block;
      width: 100%;
  `};

  ${breakpoints.tabletLandscape`
      display: flex;
      margin-bottom: 7px;
  `};
`;

export const BottomBoxForm = styled(Box)`
  ${breakpoints.phone`
      display: block;
      margin-bottom:  ${({ theme }: Theme) => theme.spacing.sp32};
      padding: 0;
  `};

  ${breakpoints.tabletLandscape`
      display: flex;
  `};
`;

export const InputWrapper = styled(Box)`
  ${breakpoints.phone`
      display: block;
      padding: 0;
  `};

  ${breakpoints.tabletLandscape`
      display: flex;
      max-height: 5em;
      
  `};
`;

export const HideOnMobile = styled(Box)`
  ${breakpoints.phone`
      display: none;
  `};

  ${breakpoints.tabletLandscape`
      display: block;
  `};
`;

export const HideOnDesktop = styled(Box)`
  ${breakpoints.phone`
      display: block;
  `};

  ${breakpoints.tabletLandscape`
      display: none;
  `};
`;

export const PreviewContainer3d = styled(Box)`
  height: 500px;
  flex-direction: column;
`;

export const PreviewContainer = styled(Box)`
  margin-top: 9rem;
`;

export const InnerWrapper = styled(Box)`
  ${breakpoints.phone`
      display: block;
      width: 81%;
      margin: 2em 0 1em;
      padding: 0;
  `};

  ${breakpoints.tabletLandscape`
      display: flex;
      margin: 2em 0 0;
      width: 100%;
  `};
`;

export const CrossSection = styled(Box)`
  ${breakpoints.phone`
      margin-top: 1em
  `};

  ${breakpoints.tabletLandscape`
      margin: 0
  `};
`;

export const Pointer = styled.div`
  cursor: pointer;
`;

export const ClearLink = styled(Link)`
  font-size: 0.8rem;
  padding-left: 6px;
`;

export const MaterialColumn = styled(GridColumn)`
  max-height: 4em;
  padding: 0;
`;

export const BottomColumn = styled(GridColumn)`
  padding: 0;
`;
