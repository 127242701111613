import "./polyfills";
/**
 * DO NOT REORDER THESE IMPORTS UNDER ANY CIRCUMSTANCES
 * CREATE REACT APP REQUIRES THE IE11 POLYFILL TO BE THE FIRST LINE OF SRC/INDEX.TSX
 * DO NOT REORDER THESE IMPORTS UNDER ANY CIRCUMSTANCES
 */
import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import GlobalStyles from "./styles/GlobalStyles";
import {
  WebshopIntegrationProvider,
  NotificationProvider,
} from "@eriksdigital/atomic-ui/components";
import { defaultTheme } from "@eriksdigital/atomic-ui/styles";
import { ThemeProvider } from "styled-components";
import { HelmetProvider } from "react-helmet-async";
import { createApolloClient } from "./createApolloClient";
import { AppProvider } from "./providers/AppProvider";
import { TranslationProvider } from "./providers/TranslationProvider";
import { GTMProvider } from "@eriksdigital/gs-ga4";

const uri: string = "/o-ring-selector/api";

export const client = createApolloClient(uri, true);

if (process.env.NODE_ENV !== "production") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React);
}

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <HelmetProvider>
        <ThemeProvider theme={defaultTheme}>
          <TranslationProvider>
            <WebshopIntegrationProvider targetSystem="SEL_ORI">
              <GTMProvider env={process.env.REACT_APP_ENVIRONMENT}>
                <AppProvider>
                  <NotificationProvider>
                    <App />
                  </NotificationProvider>
                </AppProvider>
              </GTMProvider>
            </WebshopIntegrationProvider>
          </TranslationProvider>
        </ThemeProvider>
      </HelmetProvider>
      <GlobalStyles />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
if (process.env.NODE_ENV === "development") {
  reportWebVitals(console.log);
}
