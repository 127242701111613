import { Language } from "../../utils/languageUtils";
import { valueOf } from "../../global.types";

export const colorsMap = {
  "Dark grey": "#808080",
  "Dark blue": "#00008b",
  "Light grey": "#d3d3d3",
  "Red-brown": "#a52a2a",
  Green: "#008000",
  Red: "#ff0000",
  White: "#ffffff",
  Black: "#000000",
  Natural: "#d4ccb4",
  Blue: "#0000ff",
  Brown: "#842000",
  Grey: "#a9a9a9",
  Beige: "#f5f5dc",
  Yellow: "#ffff00",
};

export const translatedColorsMap: {
  [key in Language]: Record<string, valueOf<typeof colorsMap>>;
} = {
  en: {
    ...colorsMap,
  },
  nl: {
    Blauw: colorsMap["Blue"],
    Bruin: colorsMap["Brown"],
    Donkerblauw: colorsMap["Dark blue"],
    Donkergrijs: colorsMap["Dark grey"],
    Grijs: colorsMap["Grey"],
    Groen: colorsMap["Green"],
    Lichtgrijs: colorsMap["Light grey"],
    Naturel: colorsMap["Natural"],
    Roodbruin: colorsMap["Red-brown"],
    Wit: colorsMap["White"],
    Zwart: colorsMap["Black"],
    Rood: colorsMap["Red"],
    Beige: colorsMap["Beige"],
    Geel: colorsMap["Yellow"],
  },
  de: {
    Blau: colorsMap["Blue"],
    Braun: colorsMap["Brown"],
    Dunkelblau: colorsMap["Dark blue"],
    Dunkelgrau: colorsMap["Dark grey"],
    Grau: colorsMap["Grey"],
    Grün: colorsMap["Green"],
    Lichtgrau: colorsMap["Light grey"],
    Natur: colorsMap["Natural"],
    Rotbraun: colorsMap["Red-brown"],
    Weiss: colorsMap["White"],
    Schwarz: colorsMap["Black"],
    Rot: colorsMap["Red"],
    Beige: colorsMap["Beige"],
    Gelb: colorsMap["Yellow"],
  },
  fr: {
    Bleu: colorsMap["Blue"],
    Brun: colorsMap["Brown"],
    "Bleu foncé": colorsMap["Dark blue"],
    "Gris foncé": colorsMap["Dark grey"],
    Gris: colorsMap["Grey"],
    Vert: colorsMap["Green"],
    "Gris clair": colorsMap["Light grey"],
    Naturel: colorsMap["Natural"],
    "Rouge brun": colorsMap["Red-brown"],
    Blanc: colorsMap["White"],
    Noir: colorsMap["Black"],
    Rouge: colorsMap["Red"],
    Beige: colorsMap["Beige"],
    Jaune: colorsMap["Yellow"],
  },
};
