import React, { useContext, SyntheticEvent, ReactNode } from "react";
import ReactDOM from "react-dom";
import { Toast, NotificationContext } from "@eriksdigital/atomic-ui/components";
import { breakpoints } from "@eriksdigital/atomic-ui/styles";
import { AppContext } from "../../providers/AppProvider";
import styled from "styled-components";

export interface NotificationContextInterface {
  notifications?: Notification[];
  dismissNotification?: Function;
  createNotification?: Function;
}

interface Notification {
  id: string;
  variant?: "success" | "warning" | "info" | "error";
  message?: string | ReactNode;
  autoDismiss?: boolean;
}

export const NotificationCenter = () => {
  const { notifications, dismissNotification } = useContext(
    NotificationContext
  );

  return ReactDOM.createPortal(
    <Notifications
      notifications={notifications}
      dismissNotification={dismissNotification}
    />,
    document.querySelector("#notifications") || document.createElement("div")
  );
};

export const Notifications = ({
  notifications,
  dismissNotification,
}: NotificationContextInterface) => {
  const {
    state: { shoppingCartUrl },
  } = useContext(AppContext);

  const handleDismissNotification = (e: SyntheticEvent) => {
    e.stopPropagation();
    const target = e.currentTarget as HTMLElement;

    dismissNotification && dismissNotification(target.id.split("_")[1]);
  };

  const onClickNotification = () => {
    window.open(shoppingCartUrl, "_blank");
  };

  return (
    <>
      {notifications && !!notifications.length && (
        <ToastWrapper>
          {notifications.map((notification) => {
            return (
              <Toast
                dismiss={0}
                dismissIcon={true}
                id={notification.id}
                key={notification.id}
                alert={notification.variant}
                onDismiss={handleDismissNotification}
                onClick={onClickNotification}
              >
                {notification.message}
              </Toast>
            );
          })}
        </ToastWrapper>
      )}
    </>
  );
};

const ToastWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  min-height: 200px;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  align-items: center;

  ${breakpoints.tabletLandscape`
    top: 32px;
    right: 32px;
    width: auto;  
    align-items: stretch;
  `}
`;
